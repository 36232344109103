import { TcFooter } from '@tc-packages/tc-common'
import { PropTypes } from 'prop-types'
import React from 'react'

const TCFooterWrapper = props => {

  const { footerItems } = props,
    footerLogo = props.footerItems.logo,
    logoUrl = props.logoUrl

  return (
    <div>
      <TcFooter
        footerItems={footerItems}
        footerLogo={footerLogo}
        logoUrl={logoUrl}
      />
    </div>
  )
}

TCFooterWrapper.propTypes = {
  footerItems: PropTypes.object,
  footerLogo: PropTypes.string,
  logoUrl: PropTypes.string,
}

export default TCFooterWrapper
