import { createMuiTheme } from '@material-ui/core'

const TCTheme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#fff',
      main: '#90c141',
    },
    secondary: {
      contrastText: '#fff',
      main: '#414141',
    },
    typography: {
      fontFamily: [
        '-apple-system',
        '"Lato Regular"',
      ].join(','),
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },

  },
  shadows: Array(25).fill('none'),
})

export default TCTheme
