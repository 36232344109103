import { ThemeProvider } from '@material-ui/core'
import { TcNavbar } from '@tc-packages/tc-common'
import { PropTypes } from 'prop-types'
import React from 'react'
import TCTheme from './TCTheme'

export default class TCNavbarWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      logo: props.data.logo,
      logoUrl: props.logoUrl.uri,
      menuItems: props.data.menuItems,
      pathOrigin: props.pathOrigin,
    }
  }

  render() {
    const { logo, logoUrl, menuItems, pathOrigin } = this.state

    return (
      <ThemeProvider theme={TCTheme}>
        <div>
          <TcNavbar
            logo={logo}
            logoUrl={logoUrl}
            menuItems={menuItems}
            pathOrigin={pathOrigin}
            queryString="/collection-programs?query"
          />
        </div>
      </ThemeProvider>
    )
  }
}

TCNavbarWrapper.propTypes = {
  data: PropTypes.object,
  logoUrl: PropTypes.object,
  pathOrigin: PropTypes.string,
}

TCNavbarWrapper.defaultProps = {
  logoUrl: { uri: window.location.origin },
}
